@value xs, sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value white, blue from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointSmDown, breakpointSmUp, breakpointMdUp, breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value HEADER_AND_MEGA_NAV_HEIGHT_DESKTOP_PX, HEADER_HEIGHT_MOBILE_PX  from "@/modules/styling/global.module.css";
@value shopNavigationTabs from "@/modules/styling/zIndex.module.css";
@value fontFamilyBody from "@depop/web-ui-kit/theme/default/fonts.module.css";
@value gallery from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .wrapper {
    display: flex;
    justify-content: space-between;
    padding: sm 0;
    flex-direction: column;
    position: sticky;
    top: HEADER_HEIGHT_MOBILE_PX;
    z-index: shopNavigationTabs;
    background-color: white;

    @media breakpointMdUp {
      padding: md 0;
      top: HEADER_AND_MEGA_NAV_HEIGHT_DESKTOP_PX;
      flex-direction: row;
    }
  }

  .tabsContainer {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: sm;
  }

  .navigationTabsContainer {
    display: flex;
    column-gap: md;
    flex-wrap: wrap;
    align-items: center;
  }

  .actionButtonsContainer {
    display: flex;
    gap: md;
    align-items: center;
  }

  .actionButton {
    border: 0;
    color: blue;
    padding: 0;
    background: none;
    display: none;

    span {
      font-family: fontFamilyBody;
      font-weight: normal;
      font-size: 0.75rem;
    }

    &:hover {
      background: none;
      text-decoration: underline;
    }

    &:disabled {
      color: blue;
      background: none;
      text-decoration: none;
    }

    @media breakpointSmUp {
      display: flex;
    }

    @media breakpointMdUp {
      span {
        font-size: 0.875rem;
      }
    }
  }

  .rearrangeModeActiveButton {
    svg {
      /* Reduce the size of the spinner icon to prevent the text moving when the loading state is active */
      width: 11px !important;
      height: 11px !important;
    }
  }

  .rearrangeAndMoveIcons {
    color: blue;
  }

  .rearrangeButtons {
    display: flex;
    align-items: center;
    gap: md;

    @media breakpointSmDown {
      display: none;
    }
  }

  .rearrangeModeInactiveButton {
    span {
      display: flex;
      align-items: center;
    }

    svg {
      margin-right: xs;
    }
  }

  .primaryTabs {
    border: none;
  }

  .tabsWrapper {
    flex-direction: row;
    border-bottom: 1px solid gallery;
    padding-bottom: 0;
    margin-bottom: md;
  }
}
